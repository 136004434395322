import classNames from 'classnames'
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'

type StyledInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const StyledAuthInput = React.forwardRef<HTMLInputElement | null, StyledInputProps>((props, ref) => {
  return (
    <input
      className={classNames(
        'w-full rounded-lg border border-black/10 p-0 px-4 py-2 lg:rounded-2xl',
        'text-xs placeholder:text-black/20 focus:border-black/10 focus:ring-0',
        'lg:px-6 lg:py-4 lg:text-sm',
      )}
      type="text"
      ref={ref}
      {...props}
    />
  )
})

StyledAuthInput.displayName = 'StyledAuthInput'
export default StyledAuthInput
