import { sendHubspotForm } from 'shared/utils'
import { PORTAL_ID, SIGN_UP_FORM_ID } from './constants'
import { segmentClient, trackSegmentCustomEvent } from 'shared/services/analytics/segment'
import { AuthType } from 'shared/types/Auth'

const getHubspotUtk = () => {
  const cookieValue = document.cookie
    ?.split(';')
    .find((row) => row.trim().startsWith('hubspotutk='))
    ?.split('=')[1]

  return cookieValue || null
}

export const sendHubspotSignUpFormAPI = async (firstName: string, email: string, pagePath: string) => {
  try {
    const hutk = getHubspotUtk()

    const body = {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: firstName,
        },
      ],
      context: {
        hutk,
        pageName: 'Sign Up',
        pageUri: pagePath,
      },
    }

    await sendHubspotForm(body, PORTAL_ID, SIGN_UP_FORM_ID)
  } catch (e) {
    console.error(e)
  }
}

type SegmentTrackingForSignUpProps = {
  userId: number
  signUpMethod: AuthType
  asRole?: string
  email: string
  firstName: string
  createdAt: Date
}

export const setUpSegmentTrackingForSignUp = ({
  userId,
  signUpMethod,
  asRole,
  email,
  firstName,
  createdAt,
}: SegmentTrackingForSignUpProps) => {
  segmentClient.identify(String(userId), {
    sign_up_method: signUpMethod === 'password' ? 'email' : signUpMethod,
  })
  trackSegmentCustomEvent('user_signed_up_successfully', {
    sign_up_date: createdAt,
    sign_up_method: signUpMethod === 'password' ? 'email' : signUpMethod,
    user_role: asRole || 'henry',
    email: email,
    user_id: userId,
    first_name: firstName,
  })
}
