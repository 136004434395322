import {
  AuthError,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  linkWithPopup,
  signInWithEmailAndPassword,
  TwitterAuthProvider,
} from 'firebase/auth'

import { firebaseAuth } from 'lib/firebase'

const linkTwitterToEmailAccount = async (email: string, password: string, isMobileScreen?: boolean) => {
  const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password)
  const linkingHandler = isMobileScreen ? linkWithPopup : linkWithPopup
  await linkingHandler(userCredential.user, new TwitterAuthProvider())
  return userCredential
}

const linkTwitterToGoogleAccount = async (isMobileScreen?: boolean) => {
  const linkingHandler = isMobileScreen ? linkWithPopup : linkWithPopup
  const user = firebaseAuth.currentUser
  if (!user) {
    throw 'Cannot link existed account to this Twitter account'
  }
  const userCredential = await linkingHandler(user, new TwitterAuthProvider())
  return userCredential
}

const handleExistingAccount = async (error: AuthError, isMobileScreen?: boolean) => {
  const { code: errorCode, customData } = error
  if (errorCode !== 'auth/account-exists-with-different-credential' || !customData.email) {
    throw error
  }

  const methods = await fetchSignInMethodsForEmail(firebaseAuth, customData.email)
  // If the user has several sign-in methods,
  // the first method in the list will be the "recommended" method to use.
  if (methods[0] === 'password') {
    // Asks the user their password.
    // In real scenario, you should handle this asynchronously.
    const promptMessage = `An account already exists for email: ${customData.email}\nPlease type in your Cohart's password to link:`
    const password = prompt(promptMessage)
    if (!password) throw 'User denied action!'
    const userCredential = await linkTwitterToEmailAccount(customData.email, password, isMobileScreen)
    return userCredential
  }

  if (methods[0] === GoogleAuthProvider.PROVIDER_ID) {
    const userCredential = await linkTwitterToGoogleAccount(isMobileScreen)
    return userCredential
  }

  throw 'Cannot link the existed account'
}

export default handleExistingAccount
