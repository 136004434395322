import Link from 'next/link'

const CohartLogo = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div onClick={onClick}>
      <Link href="/" className="relative z-[1] flex flex-col items-center gap-0">
        <span className="text-center text-[8px] font-semibold uppercase leading-[100%] tracking-wide text-black/25">
          Powered by
        </span>
        <span className="cursor-pointer text-center font-beni text-[50px] leading-[100%] tracking-wide">COHART</span>
      </Link>
    </div>
  )
}

export default CohartLogo
