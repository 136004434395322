const AttentionIcon = ({ color, className }: { color: string; className?: string }) => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.6427 5C9.6427 7.47863 7.60274 9.5 5.07135 9.5C2.53996 9.5 0.5 7.47863 0.5 5C0.5 2.52137 2.53996 0.5 5.07135 0.5C7.60274 0.5 9.6427 2.52137 9.6427 5Z"
        stroke={color}
      />
      <path
        d="M5.59064 2.18182L5.49689 6.28977H4.58212L4.49121 2.18182H5.59064ZM5.03951 8.0625C4.86716 8.0625 4.71943 8.00189 4.59632 7.88068C4.47511 7.75947 4.41451 7.61174 4.41451 7.4375C4.41451 7.26705 4.47511 7.12121 4.59632 7C4.71943 6.87879 4.86716 6.81818 5.03951 6.81818C5.20807 6.81818 5.3539 6.87879 5.47701 7C5.60201 7.12121 5.66451 7.26705 5.66451 7.4375C5.66451 7.55303 5.63515 7.65814 5.57644 7.75284C5.51962 7.84754 5.44386 7.9233 5.34917 7.98011C5.25636 8.03504 5.15314 8.0625 5.03951 8.0625Z"
        fill={color}
      />
    </svg>
  )
}

export default AttentionIcon
