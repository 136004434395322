import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react'

type StyledInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  isPasswordVisible: boolean
  togglePasswordVisible: () => void
}

const StyledPasswordInput = React.forwardRef<HTMLInputElement | null, StyledInputProps>((props, ref) => {
  const { isPasswordVisible, togglePasswordVisible, ...rest } = props
  return (
    <div
      className={classNames(
        'flex w-full items-center rounded-lg border border-black/10 lg:rounded-2xl',
        'px-4 py-[6px] lg:px-6 lg:py-[14px] ',
      )}
    >
      <input
        className={classNames(
          'w-full border-transparent p-0 text-xs ',
          'placeholder:text-black/20 focus:border-transparent focus:bg-transparent focus:ring-0 lg:text-sm',
        )}
        type={isPasswordVisible ? 'text' : 'password'}
        autoComplete={isPasswordVisible ? 'off' : 'on'}
        ref={ref}
        {...rest}
      />
      <div className="w-6 cursor-pointer" onClick={togglePasswordVisible}>
        {isPasswordVisible ? <EyeIcon className="size-5 lg:size-6" /> : <EyeSlashIcon className="size-5 lg:size-6" />}
      </div>
    </div>
  )
})

StyledPasswordInput.displayName = 'StyledPasswordInput'
export default StyledPasswordInput
