import Image from 'shared/components/CustomImage'
import Button from 'shared/components/button/Button'

type Props = {
  onGoogleButtonClick: () => void
  onAppleButtonClick: () => void
  onTwitterButtonClick: () => void // currently not use
  isGoogleButtonLoading: boolean
  isAppleButtonLoading: boolean
  isTwitterButtonLoading: boolean // currently not use
}

const SocialAuthButtons = (props: Props) => {
  const { onAppleButtonClick, onGoogleButtonClick, isGoogleButtonLoading, isAppleButtonLoading } = props

  // to prevent passing synthetic event to the callback
  const clickGoogleButton = () => onGoogleButtonClick()
  const clickAppleButton = () => onAppleButtonClick()

  return (
    <div className="item-center inline-flex w-full justify-center gap-2 lg:gap-4">
      {/* Google */}
      <Button
        type="button"
        wrapperClass="flex h-full w-full items-center justify-center rounded-[4px] border border-black/10 !p-2"
        contentClass="ml-2 !normal-case !text-xs !font-semibold"
        onClick={clickGoogleButton}
        name=""
        loading={isGoogleButtonLoading}
      >
        <div className="relative h-[19px] w-[19px]">
          <Image src="/icons/google.png" fill alt="google icon" />
        </div>
        <span className="ml-2 text-xs font-semibold">Google</span>
      </Button>

      {/* Apple */}
      <Button
        type="button"
        wrapperClass="flex h-full w-full items-center justify-center rounded-[4px] border border-black/10 !p-[6px]"
        contentClass="ml-2 !normal-case !text-xs !font-semibold"
        onClick={clickAppleButton}
        name=""
        loading={isAppleButtonLoading}
      >
        <div className="relative h-[23px] w-[24px]">
          <Image src="/icons/apple.svg" fill alt="apple icon" />
        </div>
        <span className="ml-2 text-xs font-semibold">Apple</span>
      </Button>
    </div>
  )
}

export default SocialAuthButtons
