import { MeasurementId } from 'configs/enums'
import { ShippingInformationFormData } from 'shared/types/V3/Artwork'
import { ArtworkShippingInformation } from 'views/creator-artwork-form/types'
import { CategoryType } from 'views/discover-home/types'

export const getShippingInformation = <T extends ShippingInformationFormData>(data: T, isForSale: boolean) => {
  const isShippingDimensionsSet = data.shippingWidth || data.shippingHeight || data.shippingDepth
  const shippingInformation: null | ArtworkShippingInformation = isForSale
    ? {
        type: data.shippingOption || 'box',
        dimensions: isShippingDimensionsSet
          ? {
              width: data.shippingWidth,
              height: data.shippingHeight,
              depth: data.shippingDepth,
              unit: data.shippingMeasument === MeasurementId.IMPERIAL ? 'in' : 'cm',
            }
          : null,
        hasDecorativeFrame: data.hasDecorativeFrame,
      }
    : null

  return shippingInformation
}

export const getUTMQueries = (params: {
  userId?: number
  utmMedium?: string
  utmCampaign: string
  utmContent: number | string
}) => {
  const { userId, utmMedium = 'referral', utmCampaign, utmContent } = params
  return `?utm_source=${
    userId ?? 'guest'
  }&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${utmContent} `
}

export const getFeaturedPageType = (categoryType: CategoryType) => {
  // NOTE: current getInspired data still have the "featured_curator" type,
  // which has now been called "featured_collector", so we have to add this check
  if (categoryType === 'featured_curator') return 'collector'
  if (categoryType === 'featured_artist') return 'artist'
  if (categoryType === 'featured_category') return 'category'
  return categoryType
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendHubspotForm = async (body: Record<string, any>, portalId: string, formId: string) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

  try {
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
  } catch (e) {
    console.error(e)
  }
}
